import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import FaceNameButton from '../FaceName/FaceNameButton'
import CircularDeterminate from '../CircularDeterminate'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  justifyContentCenter: styles.justifyContentCenter,
  faceHero: styles.faceHero,
  width95: styles.width95,
})

const Learn = ({ q }) => {
  const { getString, isPractice } = useContext(StoreContext)
  const classes = useStyles()
  const [isDisabled, setIsDisabled] = useState(true)
  const [buttonContent, setButtonContent] = useState(getString('loading'))

  useEffect(() => {
    if (isDisabled) {
      setButtonContent(
        <CircularDeterminate callback={() => setIsDisabled(false)} size={50} />
      )
    } else {
      setButtonContent(getString('nextButtonLabel'))
    }
  }, [isDisabled, setButtonContent, getString])

  const cardTitle = isDisabled ? getString('tryRememberPair') : null
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      setImageLoaded(preloadedImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadedImage.complete, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Card>
      <CardContent className={classes.width95}>
        {isPractice && <PracticeChip />}
        <Typography>{cardTitle}</Typography>
      </CardContent>
      <Grow in={isDisabled}>
        <CardContent>
          <Card>
            <CardContent>
              <CardMedia className={classes.faceHero} image={q.image} />
            </CardContent>
            <Typography variant="h4" gutterBottom>
              ${q.price}
            </Typography>
          </Card>
        </CardContent>
      </Grow>
      <CardActions className={classes.justifyContentCenter}>
        <FaceNameButton disabled={isDisabled}>{buttonContent}</FaceNameButton>
      </CardActions>
    </Card>
  )
}

export default Learn
