import React, { useContext, useState, useEffect, useReducer } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StoreContext } from '../StoreContext'

// We will update the state 50 times (0 -> 100)
const incrementor = (state) => state + 2

const CircularDeterminate = ({ callback, timer, size = 26 }) => {
  const [timerId, setTimerId] = useState(null)
  const [percentComplete, incrementPercentComplete] = useReducer(incrementor, 0)
  const { getTimer } = useContext(StoreContext)
  const [timerLoaded, setTimerLoaded] = useState(false)

  // We need to perform 50 state changes in the timeframe allowed.
  // Timer Seconds * 1000 for MS, then divide by 50 for the state changes
  // This equates to multiplying the seconds by 20
  const timerSeconds = getTimer(timer) * 20

  useEffect(() => {
    if (timerId === null || !timerLoaded) {
      setTimerLoaded(true)
      const id = setInterval(() => {
        incrementPercentComplete()
      }, timerSeconds)
      setTimerId(id)
    }
    return () => {
      clearInterval(timerId)
    }
  }, [timer, timerId, timerSeconds, timerLoaded, setTimerLoaded])

  useEffect(() => {
    if (percentComplete >= 100) {
      clearInterval(timerId)
      // The animation takes around 300 MS to complete, call callback after
      // For better transitions we can improve
      setTimeout(() => {
        callback()
      }, 300)
    }
  })

  return (
    <CircularProgress
      variant="determinate"
      value={percentComplete}
      size={size}
    />
  )
}
export default CircularDeterminate
