import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grow,
  Typography,
} from '@material-ui/core'
import { ErrorOutline, NavigateNext } from '@material-ui/icons'
import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import styles from '../css-styles'

const useStyles = makeStyles({
  errorIcon: styles.errorIcon,
  marginRight: styles.marginRight,
  justifyFlexEnd: styles.justifyFlexEnd,
})

const TestInstructions = ({
  title,
  body,
  errorCode,
  uniqueID,
  contactMessage,
  errorRedirect = null,
}) => {
  const classes = useStyles()

  let redirectButton = <></>
  if (errorRedirect !== null && errorRedirect !== '') {
    // TODO use translated "Continue"
    const errorMessage = `${body} ${contactMessage} ${errorCode}-${uniqueID}`
    const params = new URLSearchParams()
    params.set('error_code', `${errorCode}-${uniqueID}`)
    params.set('error_message', errorMessage)
    const errorRedirectWithQP = `${errorRedirect}?${params.toString()}`
    redirectButton = (
      <CardActions className={classes.justifyFlexEnd}>
        <Button
          href={errorRedirectWithQP}
          size="large"
          variant="contained"
          color="primary"
          className={classes.marginRight}
        >
          Continue
          <NavigateNext fontSize="large">Continue</NavigateNext>
        </Button>
      </CardActions>
    )
  }
  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4">
            <ErrorOutline
              className={classes.errorIcon}
              htmlColor={red[600]}
              style={{ fontSize: '3rem!important' }}
            />
            {title}
          </Typography>

          <Typography variant="body1">
            {body} {contactMessage} {errorCode}-{uniqueID}
          </Typography>
          {redirectButton}
        </CardContent>
      </Card>
    </Grow>
  )
}

export default TestInstructions
