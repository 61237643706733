import * as Sentry from '@sentry/react'

const DISABLE_SENTRY = Boolean(
  parseInt(process.env.REACT_APP_DISABLE_SENTRY, 10)
)

if (!DISABLE_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
  })
}
