import React from 'react'
import * as Sentry from '@sentry/react'
import ErrorScreen from './ErrorScreen'
import defaultData from '../defaultData'

class ErrorBoundary extends Sentry.ErrorBoundary {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      redirectOnError: null,
      errorCode: null,
      uniqueID: null,
      contactMessage: defaultData.strings.en.contactAdmin,
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
      errorCode: error.errorCode,
      uniqueID: error.uniqueID,
    }
  }

  componentDidCatch(error, info) {
    let { uniqueID } = error
    let { errorCode } = error
    if (!uniqueID || !errorCode) {
      // Generate a 36 character UUID, grab the last 6 characters for our unique ID
      uniqueID = window.crypto.randomUUID().substring(30)
      this.setState({ uniqueID })
      if (!errorCode) {
        errorCode = `UNK`
        this.setState({ errorCode })
      }
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.group('ErrorBoundary')
      console.error(error.message)
      console.log(info)
      console.groupEnd()
    } else {
      Sentry.withScope((scope) => {
        scope.setExtra('errorCode', errorCode)
        scope.setTag('errorCode', errorCode)
        scope.setExtra('uniqueID', uniqueID)
        scope.setTag('uniqueID', uniqueID)
        scope.setExtras(info)
        if (error.message !== defaultData.strings.en.invalidLink) {
          Sentry.captureException(error)
        }
        Sentry.captureException(error)
      })
    }
  }

  // Used to supress linter on no-unused state vars
  logErrorStates = () => {
    const { errorCode, uniqueID } = this.state
    console.log(errorCode, uniqueID)
  }

  // This will be called from the child component (StoreContext.JSX) to update our error redirect when retrieved
  updateErrorRedirect = (errorRedirect) => {
    this.setState({ redirectOnError: errorRedirect })
  }

  updateContactMessage = (message) => {
    this.setState({ contactMessage: message })
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return (
        <ErrorScreen
          title="Error"
          // eslint-disable-next-line react/destructuring-assignment
          body={this.state.error.message}
          // eslint-disable-next-line react/destructuring-assignment
          errorCode={this.state.errorCode}
          // eslint-disable-next-line react/destructuring-assignment
          uniqueID={this.state.uniqueID}
          // eslint-disable-next-line react/destructuring-assignment
          contactMessage={this.state.contactMessage}
          // eslint-disable-next-line react/destructuring-assignment
          errorRedirect={this.state.redirectOnError}
        />
      )
    }

    // Append updateErrorRedirect to children props, allows children to update the errorRedirect state
    // eslint-disable-next-line react/destructuring-assignment
    return React.cloneElement(this.props.children, {
      updateErrorRedirect: this.updateErrorRedirect,
      updateContactMessage: this.updateContactMessage,
    })
  }
}

export default ErrorBoundary
