import React, { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import * as Sentry from '@sentry/react'
import { Typography } from '@material-ui/core'
import { StoreContext } from '../StoreContext'

const AssignmentFeedback = () => {
  const { assignment, getString, organization, assignmentId, setErrorState } =
    useContext(StoreContext)
  const [chartJson, setChartJson] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [chartLabels, setChartLabels] = useState([])
  const [chartValues, setChartValues] = useState([])
  const [hasImproved, setHasImproved] = useState(false)
  const [feedbackText, setFeedbackText] = useState(
    getString('feedbackTextNormal')
  )

  if (!assignment.exists) {
    const newErr = Error(getString('assignment404'))
    newErr.errorCode = 'DE2'
    setErrorState({ type: 'error', error: newErr })
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )
  useEffect(() => {
    if (isLoading && chartJson == null) {
      fetch(
        `/test/${organization}/api/v2/json/assignment/${assignmentId}/scores/`,
        {
          method: 'GET',
        }
      )
        .then((response) => response.json())
        // eslint-disable-next-line no-shadow
        .then((assignmentScores) => {
          setChartJson(assignmentScores)
          const labels = []
          const values = []

          // eslint-disable-next-line no-restricted-syntax,no-unused-vars
          for (const [index, [key, value]] of Object.entries(
            Object.entries(assignmentScores)
          )) {
            const dayNumber = parseInt(index, 10) + 1
            labels.push(`${getString('dayLabel')} ${dayNumber}`)
            values.push(value)
          }
          if (values.length > 1) {
            const didImprove = values[values.length - 1] > values[0]
            setHasImproved(didImprove)
            if (didImprove) {
              setFeedbackText(getString('feedbackTextImproved'))
            }
          }

          setChartLabels(labels)
          setChartValues(values)
          setIsLoading(false)
        })
        .catch((err) => {
          Sentry.captureException(err)
          const newErr = new Error(getString('fetchFailedError'))
          newErr.errorCode = 'SE6'
          setErrorState({ type: 'error', error: newErr })
        })
    }
  }, [
    isLoading,
    chartJson,
    organization,
    assignmentId,
    getString,
    feedbackText,
    setFeedbackText,
    setHasImproved,
    hasImproved,
    setErrorState,
  ])
  return (
    <>
      <Typography align="center" variant="h6">
        {feedbackText}
      </Typography>

      <Bar
        type="bar"
        options={{
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: getString('testScores'),
            },
          },
          scales: {
            y: {
              max: 100,
              beginAtZero: true,
              // scaleLabel: {
              //   display: true,
              //   labelString: 'Percentage',
              // },
              ticks: {
                callback(value) {
                  // eslint-disable-next-line react/no-this-in-sfc
                  return `${((value / this.max) * 100).toFixed(0)}%` // convert it to percentage
                },
              },
            },
            x: {
              title: {
                text: getString('testDayAxis'),
                display: true,
              },
            },
          },
          parsing: true,
        }}
        data={{
          labels: chartLabels,
          datasets: [
            {
              label: getString('testScore'),
              data: chartValues,
              parsing: {
                xAxisKey: 'percentage',
                yAxisKey: 'percentage',
              },
              backgroundColor: (context) => {
                const index = context.dataIndex
                const value = context.dataset.data[index]
                if (value) {
                  return value.complete
                    ? 'rgba(53, 162, 235, 0.5)'
                    : 'rgba(255,100,100, 0.5)'
                }
                return 'rgba(255,100,100, 0.5)'
              },
            },
          ],
        }}
      />
    </>
  )
}

export default AssignmentFeedback
