import { createTheme } from '@material-ui/core'

const themeCustomizations = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: 'calc(1.5rem + 1vw)',
      },
    },
    MuiButton: {
      containedSizeLarge: {
        padding: '10px 27px',
        fontSize: '1.25rem',
      },
    },
    MuiFab: {
      extended: {
        borderRadius: '0.5rem',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#A800FF',
      },
      determinate: {
        height: '0.5rem',
      },
      bar1Determinate: {
        transition: 'transform 50ms linear', // Neccessary for timers post-answer to match setTimeout
        backgroundColor: '#FFD9FF',
      },
    },
    MuiChip: {
      outlined: {
        color: '#ff9100',
        fontWeight: 'bold',
        border: '2px solid #ff9100',
      },
    },
  },
  props: {
    MuiCard: {
      raised: true,
    },
  },
  typography: {
    h4: {
      fontSize: 'calc(2rem + 2vw)',
      textAlign: 'center',
    },
    body1: {
      fontSize: 'calc(1rem + 1vw)',
      textAlign: 'center',
    },
    caption: {
      fontSize: 'calc(1rem + 1vw)',
    },
  },
})

export default themeCustomizations
