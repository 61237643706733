import {
  ACTION_RECORD_QUESTION_RESULT,
  ACTION_RECORD_ACTIVITY_TIME,
  ACTION_INCREMENT,
  ACTION_RESET,
  ACTION_RECORD_CONFIDENCE_LEVEL,
  ACTION_INCREMENT_PHASE,
  ACTION_INCREMENT_QUESTION,
  ACTION_GOTO_PHASE,
  RC_SURVEY,
  ACTION_SET_PHASE_AND_QUESTION,
  ACTION_SET_RESULTS,
} from './constants'

export const locationReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION_INCREMENT_PHASE:
      return { phase: state.phase + 1, question: 0 }
    case ACTION_INCREMENT_QUESTION:
      return { ...state, question: state.question + 1 }
    case ACTION_GOTO_PHASE:
      return { ...state, phase: payload.phase }
    case ACTION_SET_PHASE_AND_QUESTION:
      return { phase: payload.phase, question: payload.question }
    case ACTION_RESET:
      return {
        phase: 0,
        question: 0,
      }
    default:
      throw new Error(
        'locationReducer.jsx: locationReducer received invalid action type!'
      )
  }
}

export const simpleReducer = (prevState, action) => {
  switch (action) {
    case ACTION_INCREMENT:
      return prevState + 1
    case ACTION_RESET:
      return 0
    default:
      throw new Error(
        'StoreContext.jsx: simpleReducer received invalid action type!'
      )
  }
}

export const dataReducer = (prevData, { payload }) => {
  return {
    ...prevData,
    ...payload,
  }
}

export const resultsReducer = (
  prevResults,
  { type, payload, afterFeedback }
) => {
  const newResults = { ...prevResults }
  const { onPhase, onQuestion, phase, question, timeTaken } = payload
  const resultKey = afterFeedback ? `af-${onPhase}` : onPhase
  if (type === ACTION_SET_RESULTS) {
    return payload
  }
  if (newResults[resultKey] === undefined) {
    newResults[resultKey] = {
      databasePhaseId: phase.id,
      task: phase.task,
      name: phase.name,
      isPractice: phase.is_practice,
      isLearning: phase.is_learning,
      shouldOutput: phase.should_write_to_spreadsheet,
      spreadsheetKey: phase.spreadsheet_key,
      phase_rc_comp: phase.react_component,
    }
    if (phase.should_count_correct) {
      newResults[resultKey].shouldCountCorrect = true
    }
  }

  switch (type) {
    case ACTION_RECORD_ACTIVITY_TIME: {
      newResults[resultKey].totalTime = timeTaken
      return newResults
    }
    case ACTION_RECORD_CONFIDENCE_LEVEL: {
      const { wasConfident } = payload
      newResults[resultKey][onQuestion].confident = wasConfident
      return newResults
    }
    case ACTION_RECORD_QUESTION_RESULT: {
      const { attemptNumber, didAnswerCorrectly } = payload
      const resultObj = {
        name: question.name,
        attempts: attemptNumber,
        isStimuliCorrect: question.is_correct,
        correct: didAnswerCorrectly,
        reactionTime: timeTaken,
        databaseQuestionId: question.id,
        columnKey: question.column_key,
      }
      if (Object.hasOwnProperty.call(question, 'stimuli_type')) {
        // For recording the stimuli type (detector, foil, or blank) in groceries pic matching
        resultObj.stimuliType = question.stimuli_type
      }
      if (Object.hasOwnProperty.call(question, 'fnr_type')) {
        // For recording the face name recognition type (job or name)
        resultObj.fnrType = question.fnr_type
      }
      if (phase.react_component === RC_SURVEY) {
        const {
          response,
          hasExtraInput,
          extraTextInput,
          hasFollowupQuestion,
          followupResp,
          hasFollowupText,
          followupText,
        } = payload
        resultObj.userResponse = response
        resultObj.has_extra_input = hasExtraInput
        if (resultObj.has_extra_input) {
          resultObj.extra_input = extraTextInput
        }
        resultObj.has_followup_question = hasFollowupQuestion
        if (resultObj.has_followup_question) {
          resultObj.followup_response = followupResp
          resultObj.has_followup_text = hasFollowupText
          if (resultObj.has_followup_text) {
            resultObj.followup_text = followupText
          }
        }
      }
      newResults[resultKey][onQuestion] = resultObj
      return newResults
    }
    default:
      throw new Error(
        'reducers.jsx: resultsReducer received invalid action type!'
      )
  }
}

// https://github.com/facebook/react/issues/14981#issuecomment-533367899
// React will skip past error events in asynchronous calls, preventing our ErrorBoundary.jsx from catching them
// This errorReducer allows us to update the state after the promise is finished, thus bubbling up the error to ErrorBoundary
export const errorReducer = (state, action) => {
  if (action.type === 'error' && action.error) {
    throw action.error
  }
}
