import React, { useContext } from 'react'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grow,
  Typography,
} from '@material-ui/core'

import { Assignment } from '@material-ui/icons'
import ButtonNext from './ButtonNext'
import { StoreContext } from '../StoreContext'
import AssignmentFeedback from './AssignmentFeedback'

const AssignmentWelcome = () => {
  const { assignment, getString, startAssignment, setErrorState } =
    useContext(StoreContext)

  if (!assignment.exists) {
    const newErr = new Error(getString('assignment404'))
    newErr.errorCode = 'DE1'
    setErrorState({ type: 'error', error: newErr })
  }

  let body = null
  if (!assignment.hasStarted) {
    body = (
      <>
        <Typography variant="body1" gutterBottom>
          {getString('assignmentNotStarted')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {`${getString('pleaseReturn')} ${assignment.timeUntil}`}.
        </Typography>
      </>
    )
  } else if (assignment.isComplete) {
    body = (
      <>
        <Typography variant="body1" gutterBottom>
          {getString('assignmentConcluded')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {getString('assignmentAppreciation')}
        </Typography>
      </>
    )
    if (assignment.displayFeedback) {
      body = (
        <>
          <Typography variant="body1" gutterBottom>
            {getString('assignmentConcluded')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {getString('assignmentAppreciation')}
          </Typography>
          <AssignmentFeedback />
        </>
      )
    }
  } else if (assignment.inWaitingPeriod) {
    body = (
      <>
        <Typography variant="body1" gutterBottom>
          {getString('inWaitingPeriod')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {`${getString('pleaseReturn')} ${assignment.timeUntil}`}.
        </Typography>
      </>
    )
  }

  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {getString('assignmentWelcome')}
          </Typography>
          {body}
          <List>
            {assignment.hasStarted && !assignment.isComplete && (
              <ListItem>
                <ListItemIcon>
                  <Assignment />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'caption' }}
                  primary={`${assignment.testNumber} of ${assignment.testCount}`}
                  secondary="Test"
                />
              </ListItem>
            )}
          </List>
        </CardContent>
        {assignment.hasStarted &&
          !assignment.isComplete &&
          !assignment.inWaitingPeriod && (
            <ButtonNext onClick={startAssignment}>
              {getString('beginButtonLabel')}
            </ButtonNext>
          )}
      </Card>
    </Grow>
  )
}

export default AssignmentWelcome
