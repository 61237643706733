import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { StylesProvider, ThemeProvider } from '@material-ui/styles'
import * as Sentry from '@sentry/react'
import themeCustomizations from './Theme'
import Store from './StoreContext'
import ErrorBoundary from './components/ErrorBoundary'
import Router from './components/Router'

const App = () => {
  return (
    <>
      <CssBaseline />
      <StylesProvider>
        <ThemeProvider theme={themeCustomizations}>
          <ErrorBoundary>
            <Store>
              <div
                style={{
                  display: 'grid',
                  justifyItems: 'center',
                  alignItems: 'center',
                  marginTop: 'calc(var(--rvh, 1vh) * 1)',
                  marginBottom: 'calc(var(--rvh, 1vh) * 1)',
                  width: '96vw',
                  height: '100%',
                  maxWidth: '800px',
                }}
              >
                <Router />
              </div>
            </Store>
          </ErrorBoundary>
        </ThemeProvider>
      </StylesProvider>
    </>
  )
}

export default Sentry.withProfiler(App)
