// This is frequently subtracted from onQuestion to get the correct question data
// from the data array. The -1 represents the instructions screen at the start
// of most activities
export const OFFSET_ACT_INSTRUCTIONS = 1

export const QUESTIONS_KEY = 'questions'

// Constants for timers
export const TIMER_INSTRUCTIONS = 'INS'
export const TIMER_SHORT_DELAY = 'SDEL'

// Reducer constants
export const ACTION_RECORD_QUESTION_RESULT = 'RQR'
export const ACTION_RECORD_ACTIVITY_TIME = 'RAT'
export const ACTION_INCREMENT = 'INC'
export const ACTION_RESET = 'RES'
export const ACTION_RECORD_CONFIDENCE_LEVEL = 'RCL'
export const ACTION_INCREMENT_PHASE = 'INCP'
export const ACTION_INCREMENT_QUESTION = 'INCQ'
export const ACTION_GOTO_PHASE = 'GOP'
export const ACTION_SET_PHASE = 'SETP'
export const ACTION_SET_PHASE_AND_QUESTION = 'SETPQ'
export const ACTION_SET_RESULTS = 'SETRS'

// These must stay in sync with the Phase react_component choices in the Django models.py file
export const RC_AUDIO_EXP = 'audio_explanation'
export const RC_CATEGORIES_LEARNING = 'categories_learning'
export const RC_CATEGORIES_RECALL = 'categories_recall'
export const RC_DIGIT_SIGN = 'digit_sign'
export const RC_DIGIT_SIGN_RECALL = 'digit_sign_recall'
export const RC_FACE_NAME_ASSOCIATE = 'facename_associate'
export const RC_FACE_NAME_LEARN = 'facename_learn'
export const RC_FACE_NAME_RECOGNIZE = 'facename_recognize'
export const RC_FNAME_LEARNING = 'fname_learning'
export const RC_FNAME_MATCHING = 'fname_matching'
export const RC_FNAME_RECALL = 'fname_recall'
export const RC_FNAME_RECOGNITION = 'fname_recognition'
export const RC_GROCERIES_LEARN = 'groceries_learn'
export const RC_GROCERIES_PAIR = 'groceries_pair'
export const RC_GROCERIES_PICMATCHING = 'groceries_pic_matching'
export const RC_GROCERIES_RECALL = 'groceries_recall'
export const RC_INSTRUCTIONS = 'instructions'
export const RC_SIGN = 'sign'
export const RC_SURVEY = 'survey'
export const RC_TIMER_EXP = 'timer_explanation'
export const RC_THANK_YOU = 'thank_you'

// Constants for Survey Question Types
export const SURVEY_MC = 0
export const SURVEY_FREE = 1
export const SURVEY_SLIDER = 2
export const SURVEY_MS = 3
