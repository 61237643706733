import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Card, CardContent, Grow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as Sentry from '@sentry/react'
import PracticeChip from '../PracticeChip'
import FaceButton from './FaceButton'
import Timer from '../Timer'
import { StoreContext } from '../../StoreContext'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  picMatchingContainer: styles.picMatchingContainer,
})

const Recognition = ({ q }) => {
  const store = useContext(StoreContext)
  const { setHasError } = store
  const classes = useStyles()
  useQuestionStartTime()
  const [faceButtons, setFaceButtons] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [preloadList, setPreloadList] = useState([])
  const [sleepCounter, setSleepCounter] = useState(0)
  const testVariationKey = store.data.testVariation.key

  useLayoutEffect(() => {
    if (testVariationKey === '') {
      const newErr = new Error(
        `Missing spreadsheet key for this test variation.`
      )
      newErr.errorCode = 'DE3'
      store.setErrorState({ type: 'error', error: newErr })
    }
    if (!Object.hasOwnProperty.call(q.order_recognition, testVariationKey)) {
      const newErr = new Error(
        `Unable to find test variation key: ${testVariationKey} in Keyed F Name Orderings for ${q.name}`
      )
      newErr.errorCode = 'DE4'
      const scope = new Sentry.Scope()
      scope.setContext('question_obj', q)
      scope.setContext('key', testVariationKey)
      Sentry.captureException(newErr, scope)
      store.setErrorState({ type: 'error', error: newErr })
    }

    const order = q.order_recognition[testVariationKey].split(',')
    const orderedButtons = []

    order.forEach((position) => {
      const trimmedPosition = position.trim()
      if (trimmedPosition === 'T') {
        orderedButtons.push(
          <FaceButton
            answer={{ image: q.target_image, isCorrect: true }}
            key={0}
          />
        )
      } else if (trimmedPosition === '1') {
        orderedButtons.push(
          <FaceButton
            answer={{
              image: q.foil_image_1,
              isCorrect: false,
            }}
            key={1}
          />
        )
      } else if (trimmedPosition === '2') {
        orderedButtons.push(
          <FaceButton
            answer={{
              image: q.foil_image_2,
              isCorrect: false,
            }}
            key={2}
          />
        )
      } else {
        setHasError(true)
      }
    })

    setFaceButtons(orderedButtons)
  }, [q, setHasError, testVariationKey, store])

  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadList.length === 0) {
    const dummyImages = []
    const imageUrls = [q.target_image, q.foil_image_1, q.foil_image_2]
    imageUrls.forEach((imageUrl) => {
      const dummyImage = new Image()
      dummyImage.src = imageUrl
      dummyImages.push(dummyImage)
    })
    setPreloadList(dummyImages)
  }
  useEffect(() => {
    // used to increment a counter each second to rerun the effect hook to check for image load completion
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    const allImagesLoaded = preloadList.reduce(
      (previousValue, currentValue) => {
        return previousValue && currentValue.complete
      },
      true
    )
    if (allImagesLoaded) {
      setImageLoaded(allImagesLoaded)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, preloadList, setImageLoaded, sleepCounter])

  if (!imageLoaded) {
    return <Loading />
  }
  return (
    <Card className={classes.width95}>
      {store.isPractice && (
        <CardContent>
          <PracticeChip />
        </CardContent>
      )}
      <Grow in>
        <CardContent className={classes.picMatchingContainer}>
          {faceButtons}
        </CardContent>
      </Grow>
      <Timer duration={store.getTimer()} onTimeExpired={store.onTimeExpired} />
    </Card>
  )
}

export default Recognition
